import React, { useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TextInputForm } from "../../components/Forms";
import { ClickButton } from "../../components/ClickButton";
import PageNav from "../../components/PageNav";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import API_DOMAIN from "../../config/config";
import "react-toastify/dist/ReactToastify.css";

const CustomerCreations = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const initialState = {
    customer_no: "",
    customer_name: "",
    mobile_number: "",
    name_of_guardians: "",
    address: "",
    proof: [], // Changed to store only the base64 data
  };

  const [formData, setFormData] = useState(initialState);

  const validateForm = () => {
    for (const key in formData) {
      if (key !== "proof" && formData[key] === "") {
        toast.error(`${key.replace("_", " ")} cannot be empty!`, {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
        return false;
      }
    }
    return true;
  };

  const handleChange = (e, fieldName) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  // Handle file change (upload multiple images)
  const handleFileChange = (files) => {
    if (files) {
      const fileArray = Array.from(files);

      fileArray.forEach((file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (file.type.startsWith("image/")) {
            // For image files, convert them to Base64
            const base64Data = reader.result;
            setFormData((prevData) => ({
              ...prevData,
              proof: [
                ...prevData.proof,
                { type: "image", data: base64Data, name: file.name },
              ],
            }));
          } else if (file.type === "application/pdf") {
            // For PDF files, convert to Base64 as well
            const base64Data = reader.result;
            setFormData((prevData) => ({
              ...prevData,
              proof: [
                ...prevData.proof,
                { type: "pdf", data: base64Data, name: file.name },
              ],
            }));
          } else {
            toast.error("Invalid file format. Please upload valid files.", {
              position: "top-center",
              autoClose: 2000,
              theme: "colored",
            });
          }
        };

        if (file.type.startsWith("image/") || file.type === "application/pdf") {
          reader.readAsDataURL(file); // Convert image and pdf to Base64
        } else {
          toast.error(
            "Invalid file format. Only images and PDFs are allowed.",
            {
              position: "top-center",
              autoClose: 2000,
              theme: "colored",
            }
          );
        }
      });

      toast.success("File(s) uploaded successfully!", {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
      });
    } else {
      toast.error("Please upload valid files.", {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
      });
    }
  };

  // Handle image deletion

  const handleImageDelete = (index) => {
    const newProof = formData.proof.filter((_, i) => i !== index);
    setFormData({ ...formData, proof: newProof });

    if (newProof.length === 0) {
      fileInputRef.current.value = ""; // Reset file input
    }

    toast.info("File removed successfully!", {
      position: "top-center",
      autoClose: 2000,
      theme: "colored",
    });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await fetch(`${API_DOMAIN}/customer.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();
      if (responseData.head.code === 200) {
        toast.success(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
        setTimeout(() => navigate("/console/master/customer"), 1000);
      } else {
        toast.error(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while submitting the form.", {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col lg="12" className="py-3">
          <PageNav pagetitle="Customer Creation" />
        </Col>
        <Col lg="3" className="py-3">
          <TextInputForm
            placeholder="ரசீது எண்*"
            labelname="ரசீது எண்*"
            name="customer_no"
            value={formData.customer_no}
            onChange={(e) => handleChange(e, "customer_no")}
          />
        </Col>
        <Col lg="3" className="py-3">
          <TextInputForm
            placeholder="வாடிக்கையாளர் பெயர்*"
            labelname="வாடிக்கையாளர் பெயர்*"
            name="customer_name"
            value={formData.customer_name}
            onChange={(e) => handleChange(e, "customer_name")}
          />
        </Col>
        <Col lg="3" className="py-3">
          <TextInputForm
            placeholder="மொபைல் எண்*"
            labelname="மொபைல் எண்*"
            name="mobile_number"
            value={formData.mobile_number}
            onChange={(e) => handleChange(e, "mobile_number")}
          />
        </Col>
        <Col lg="3" className="py-3">
          <TextInputForm
            placeholder="தந்தை அல்லது தாய் பெயர்"
            labelname="தந்தை அல்லது தாய் பெயர்"
            name="name_of_guardians"
            value={formData.name_of_guardians}
            onChange={(e) => handleChange(e, "name_of_guardians")}
          />
        </Col>
        <Col lg="3" className="py-3">
          <TextInputForm
            placeholder="முகவரி "
            labelname="முகவரி "
            name="address"
            value={formData.address}
            onChange={(e) => handleChange(e, "address")}
          />
        </Col>
        <Col lg="3" className="py-3">
          <div className="file-upload">
            <label htmlFor="proof">Upload Proof</label>
            <input
              type="file"
              id="proof"
              accept=".pdf, .xlsx, .xls, image/*" // PDF, Excel மற்றும் image கோப்புகளை அனுமதிக்க
              ref={fileInputRef}
              multiple
              onChange={(e) => handleFileChange(e.target.files)}
            />
            {formData.proof.length > 0 && (
              <div className="file-preview">
                {formData.proof.map((file, index) => (
                  <div key={index} className="file-item">
                    {file.type === "image" ? (
                      <img
                        src={file.data}
                        alt={`Preview ${index}`}
                        style={{ width: "100px", marginTop: "10px" }}
                      />
                    ) : (
                      <div className="file-info">
                        <p>{file.name}</p>{" "}
                        {/* Display file name for PDFs and other types */}
                      </div>
                    )}
                    <div className="mt-2">
                      <ClickButton
                        onClick={() => handleImageDelete(index)}
                        className="btn btn-danger btn-sm"
                        label="Delete"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Col>

        <Col
          lg="12"
          className="py-3 text-center d-flex flex-row justify-content-center gap-3" // Added gap-3
        >
          <div className="d-flex justify-content-center">
            <ClickButton
              label="Submit"
              onClick={handleSubmit}
              disabled={loading}
            />
          </div>
          <div className="d-flex justify-content-center">
            <ClickButton
              label="Cancel"
              onClick={() => navigate("/console/master/customer")}
            />
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default CustomerCreations;
